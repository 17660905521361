import { Locale } from "../types/locale";

export const SIZES = {
    LARGE: "large",
    REGULAR: "regular",
    SMALL: "small",
} as const;

export const FILLS = {
    BLACK: "black",
    GRAY: "gray",
} as const;

export const TRANSFORMS = {
    ROTATE_180: "rotate(180)",
} as const;

export const NAVIGATION_ITEM_COLORS = {
    BLACK: "black",
    FIRE: "fire",
    ORCA: "orca",
} as const;

export const HEADING_LENGTHS = {
    TINY: 30,
    SMALL: 35,
    REGULAR: 50,
    LARGE: 80,
};

const RETAILER_NAVIGATION = "Retailer";
const FOOTER_NAVIGATION = "Footer";
const FOOTER_REGIONAL_NAVIGATION = "Regional Footer";
const WEB_TOP_NAVIGATION = "Web top";
const ALL_CATEGORIES_NAVIGATION = "All Categories";
const ALL_GIFT_CARDS = "All Gift Cards";

export const NAVIGATIONS = {
    ALL_CATEGORIES: ALL_CATEGORIES_NAVIGATION,
    ALL_GIFT_CARDS: ALL_GIFT_CARDS,
    WEB_TOP: WEB_TOP_NAVIGATION,
    FOOTER: FOOTER_NAVIGATION,
    FOOTER_REGIONAL: FOOTER_REGIONAL_NAVIGATION,
    RETAILER: RETAILER_NAVIGATION,
} as const;

export const NUM_INSTALLMENTS = 4;
export const GC_ORDER_STATUS_POLLING_INTERVAL = 1000;
export const INITIAL_GC_ORDER_STATUS_POLL_DELAY = 1000;

export const STALE_IF_ERROR_PAGE_PATH = "/2bf0daee";

export const DEFAULT_SEARCH_PER_PAGE_VALUE = 100;
export const DEFAULT_OFFSET_VALUE = 36;
export const DEFAULT_PER_PAGE_VALUE = 36;
export const MAX_NUM_FEATURED_STORES = 2;
export const STORE_LOCATIONS_CONTEXT = "store";
export const CATEGORY_LOCATIONS_CONTEXT = "category";
export const GOOGLE_MAPS_DIRECTIONS_URL = "https://www.google.com/maps/dir/?api=1&destination=";

// Heroku Metadata - https://devcenter.heroku.com/articles/dyno-metadata
// export const HEROKU_APP_ID = process.env.HEROKU_APP_ID;
// export const HEROKU_APP_NAME = process.env.HEROKU_APP_NAME;
// export const HEROKU_DYNO_ID = process.env.HEROKU_DYNO_ID;
// export const HEROKU_RELEASE_CREATED_AT = process.env.HEROKU_RELEASE_CREATED_AT;
// export const HEROKU_RELEASE_VERSION = process.env.HEROKU_RELEASE_VERSION;
export const HEROKU_SLUG_COMMIT = process.env.HEROKU_SLUG_COMMIT;
// export const HEROKU_SLUG_DESCRIPTION = process.env.HEROKU_SLUG_DESCRIPTION;

export const GIT_COMMIT_SHA = HEROKU_SLUG_COMMIT;
export const IS_SERVER = process.env.NEXT_IS_SERVER === "true";
export const ENABLE_NBI_DEV = process.env.ENABLE_NBI_DEV === "true";
export const HOST = process.env.NEXT_PUBLIC_HOST_AFTERPAY;
export const HOST_EU = process.env.NEXT_PUBLIC_HOST_CLEARPAY;
export const HOST_EN_GB = process.env.NEXT_PUBLIC_HOST_CLEARPAY_GB;
export const CSP = process.env.NEXT_PUBLIC_CSP;
export const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;
export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW;
export const SHOP_DIRECTORY_API_HOST = process.env.NEXT_PUBLIC_SHOP_DIRECTORY_API_HOST;
export const GIFT_CARD_SERVICE_HOST_US = process.env.NEXT_PUBLIC_GIFT_CARD_SERVICE_HOST_US;
export const GIFT_CARD_SERVICE_HOST_AU = process.env.NEXT_PUBLIC_GIFT_CARD_SERVICE_HOST_AU;
export const GIFT_CARD_SERVICE_HOST_EU = process.env.NEXT_PUBLIC_GIFT_CARD_SERVICE_HOST_EU;
export const SHOP_FEED_API_HOST = process.env.NEXT_PUBLIC_SHOP_FEED_API_HOST;
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const BUILDER_API_KEY = process.env.NEXT_PUBLIC_BUILDER_API_KEY;
export const BUILDER_SHOULD_BUBBLE_PAGE_ERRORS =
    process.env.NEXT_BUILDER_SHOULD_BUBBLE_PAGE_ERRORS === "true";
export const NEXT_PAGE_REVALIDATE = Number(process.env.NEXT_PAGE_REVALIDATE ?? 1);
export const BUILDER_API_CALLS_TIMEOUT_THRESHOLD = Number(
    process.env.NEXT_BUILDER_API_CALLS_TIMEOUT_WARNING_THRESHOLD ?? 3000
);
export const LAUNCH_DARKLY_SDK_KEY = process.env.LAUNCH_DARKLY_SDK_KEY;
export const SHOP_SERVICE_HOST = process.env.NEXT_PUBLIC_SHOP_SERVICE_HOST;

export const PORTAL_UI_US_HOST = process.env.PORTAL_UI_US_HOST;
export const PORTAL_UI_AU_HOST = process.env.PORTAL_UI_AU_HOST;
export const PORTAL_UI_EU_HOST = process.env.PORTAL_UI_EU_HOST;
export const PORTAL_UI_GB_HOST = process.env.PORTAL_UI_GB_HOST;

export const PORTAL_API_US_HOST = process.env.NEXT_PORTAL_API_US_HOST;
export const PORTAL_API_AU_HOST = process.env.NEXT_PORTAL_API_AU_HOST;
export const PORTAL_API_EU_HOST = process.env.NEXT_PORTAL_API_EU_HOST;
export const PORTAL_API_GB_HOST = process.env.NEXT_PORTAL_API_GB_HOST;

export const PORTAL_BRANCH_SIGN_UP_GB_URL = process.env.PORTAL_BRANCH_SIGN_UP_GB_URL;
export const PORTAL_BRANCH_SIGN_UP_AU_URL = process.env.PORTAL_BRANCH_SIGN_UP_AU_URL;
export const PORTAL_BRANCH_SIGN_UP_NZ_URL = process.env.PORTAL_BRANCH_SIGN_UP_NZ_URL;
export const PORTAL_BRANCH_SIGN_UP_US_URL = process.env.PORTAL_BRANCH_SIGN_UP_US_URL;
export const PORTAL_BRANCH_SIGN_UP_CA_URL = process.env.PORTAL_BRANCH_SIGN_UP_CA_URL;

export const API_HOST_LOCALE_MAP: Record<Locale, string | null> = {
    "en-US": PORTAL_API_US_HOST,
    "en-CA": PORTAL_API_US_HOST,
    "fr-CA": PORTAL_API_US_HOST,
    "en-AU": PORTAL_API_AU_HOST,
    "en-NZ": PORTAL_API_AU_HOST,
    "es-ES": PORTAL_API_EU_HOST,
    "it-IT": PORTAL_API_EU_HOST,
    "fr-FR": PORTAL_API_EU_HOST,
    "en-GB": PORTAL_API_GB_HOST,
    global: null,
};

export const GIFT_CARD_SERVICE_HOST_LOCALE_MAP: Record<Locale, string | null> = {
    "en-US": GIFT_CARD_SERVICE_HOST_US,
    "en-CA": GIFT_CARD_SERVICE_HOST_US,
    "fr-CA": GIFT_CARD_SERVICE_HOST_US,
    "en-AU": GIFT_CARD_SERVICE_HOST_AU,
    "en-NZ": GIFT_CARD_SERVICE_HOST_AU,
    "es-ES": null,
    "it-IT": null,
    "fr-FR": null,
    "en-GB": GIFT_CARD_SERVICE_HOST_EU,
    global: null,
};

export const ONETRUST_ID_EU = process.env.NEXT_PUBLIC_ONETRUST_ID_EU;
export const ONETRUST_ID_GB = process.env.NEXT_PUBLIC_ONETRUST_ID_GB;
export const LOCALE_TO_ONETRUST_RESOURCE_MAP: {
    [key in Locale]?: {
        id: string;
        otAutoBlockURL: string;
        otSDKStubURL: string;
    };
} = {
    "en-GB": {
        id: ONETRUST_ID_GB,
        otAutoBlockURL: `https://cdn-ukwest.onetrust.com/consent/${ONETRUST_ID_GB}/OtAutoBlock.js`,
        otSDKStubURL: "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
    },
    "es-ES": {
        id: ONETRUST_ID_EU,
        otAutoBlockURL: `https://cdn.cookielaw.org/consent/${ONETRUST_ID_EU}/OtAutoBlock.js`,
        otSDKStubURL: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    },
    "it-IT": {
        id: ONETRUST_ID_EU,
        otAutoBlockURL: `https://cdn.cookielaw.org/consent/${ONETRUST_ID_EU}/OtAutoBlock.js`,
        otSDKStubURL: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    },
    "fr-FR": {
        id: ONETRUST_ID_EU,
        otAutoBlockURL: `https://cdn.cookielaw.org/consent/${ONETRUST_ID_EU}/OtAutoBlock.js`,
        otSDKStubURL: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    },
};

export const FILTERED_GC_CODES =
    process.env.NEXT_PUBLIC_FILTERED_GC_CODES == null
        ? ["WALMART", "SAM_S_CLUB_USD", "MACYS"]
        : process.env.NEXT_PUBLIC_FILTERED_GC_CODES.split(",").map((e) => e.trim());
export const FILTERED_STORE_IDS =
    process.env.NEXT_PUBLIC_FILTERED_STORE_IDS == null
        ? ["165379", "137512"]
        : process.env.NEXT_PUBLIC_FILTERED_STORE_IDS.split(",").map((e) => e.trim());

export const AFTERPAY_ENV = process.env.AFTERPAY_ENV;
export const NEXT_PUBLIC_AFTERPAY_ENV = process.env.NEXT_PUBLIC_AFTERPAY_ENV;

export const SENTRY_ORG = process.env.SENTRY_ORG;
export const SENTRY_PROJECT = process.env.SENTRY_PROJECT;
export const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const SENTRY_SERVER_ROOT_DIR = process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR;

export type NAVIGATION = typeof NAVIGATIONS[keyof typeof NAVIGATIONS];
export type FILL = typeof FILLS[keyof typeof FILLS];
export type TRANSFORM = typeof TRANSFORMS[keyof typeof TRANSFORMS];
export type NAVIGATION_ITEM_COLOR =
    typeof NAVIGATION_ITEM_COLORS[keyof typeof NAVIGATION_ITEM_COLORS];

export const LOCALES_WITH_NO_SEARCH: Locale[] = ["fr-FR", "es-ES", "it-IT"];

export const FRITES_LOCALES: Locale[] = ["fr-FR", "es-ES", "it-IT"];

export const TRUSTPILOT_API_HOST = process.env.NEXT_TRUSTPILOT_API_HOST;
export const TRUSTPILOT_API_KEY = process.env.NEXT_TRUSTPILOT_API_KEY;
export const TRUSTPILOT_BUSINESS_UNIT = process.env.NEXT_TRUSTPILOT_BUSINESS_UNIT;
export const TRUSTPILOT_BUSINESS_UNIT_GB = process.env.NEXT_TRUSTPILOT_BUSINESS_UNIT_GB;

export const GIFT_CARD_LOCALES_IN_PROD: Array<Locale> = [
    "en-AU",
    "en-US",
    "en-GB",
    "en-NZ",
    "en-CA",
    "fr-CA",
];

export const GIFT_CARD_LOCALES_IN_NONPROD: Array<Locale> = [
    "en-AU",
    "en-US",
    "en-GB",
    "en-NZ",
    "en-CA",
    "fr-CA",
];
export const REQUEST_ID_STORE_KEY = "req-id";
export const LD_USER_LOCAL_STORAGE_KEY = "LD_USER_KEY";
export const AUTH_USER_DATA_COOKIE = "NEXT_USER_DATA";
export const AUTH_USER_DATA_STORE_KEY = "auth-user-data";
export const NEW_USER_SEEN_WELCOME_MODAL_STORE_KEY = "new-user-seen-welcome-modal";
export const DEFAULT_REQUEST_TIMEOUT = 10000;
export const CORRELATION_ID_HEADER_KEY = "correlation-id";
export const SEARCH_PAGE_COR_ID_COOKIE_KEY = "S_COR_ID";
export const USER_AGENT =
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.55 Safari/537.36";
export const WEB_STORES_SEARCH_HEADER_KEY = "REQUEST-SOURCE";
export const WEB_STORES_SEARCH_HEADER_VALUE = "Web";

export const TWO_MINUTES = 120;
export const FIVE_MINUTES = 300;
export const ONE_HOUR = 3600;
export const ONE_DAY = 86400;
export const ONE_YEAR = 31536000;
