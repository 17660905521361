import Axios from "axios";

import { CORRELATION_ID_HEADER_KEY, DEFAULT_REQUEST_TIMEOUT } from "../shared/constants";
import logger from "./logger";
import { getContinuationRequestId } from "./requests";

const axios = Axios.create({
    timeout: DEFAULT_REQUEST_TIMEOUT,
    headers: {
        "Content-Type": "application/json",
    },
});

axios.interceptors.request.use(
    (request) => {
        const corelationId = getContinuationRequestId(true);
        request.headers[CORRELATION_ID_HEADER_KEY] = corelationId;
        return request;
    },
    (ex) => {
        const correlationId = ex?.request?.getHeader?.(CORRELATION_ID_HEADER_KEY);

        const errorDescription = `request error: URL=${ex?.config?.url} correlationId=${correlationId}`;
        logger.logException(ex, errorDescription);

        // Throw the error to not change default behavior
        throw ex;
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (ex) => {
        const correlationId = ex?.request?.getHeader?.(CORRELATION_ID_HEADER_KEY);

        const errorDescription = `response error: URL=${ex?.config?.url} correlationId=${correlationId}`;
        const httpStatusCodes = ex?.response?.status;
        if (httpStatusCodes === 404) {
            logger.logWarn(ex, errorDescription);
        } else {
            logger.logException(ex, errorDescription);
        }

        // Throw the error to not change default behavior
        throw ex;
    }
);

const CancelToken = Axios.CancelToken;
export { axios, CancelToken };
