// Using our axios instance causing an axios error for this request.
// This seems to be related to us adding the correlation ID.
// Since this call can only happen client side,
// we don't really need this functionality and we can use axios directly
import { useMoney } from "@afterpaytouch/core";
import { Mutex } from "async-mutex";
import Axios, { AxiosInstance, AxiosResponse } from "axios";
import React, { useState, useEffect, createContext, useContext, useMemo, useCallback } from "react";

import {
    API_HOST_LOCALE_MAP,
    CORRELATION_ID_HEADER_KEY,
    DEFAULT_REQUEST_TIMEOUT,
} from "@/shared/constants";

import { axios } from "@/utils/axios";
// import { API_HOST_LOCALE_MAP, AUTH_USER_DATA_COOKIE } from "@/shared/constants";
// import { useCookies } from "@/hooks/useCookies";
import logger from "@/utils/logger";
import { getContinuationUserCookie } from "@/utils/requests";

import { setUserDataProperties } from "@/analytics/tracking";

import { AuthUserData, AuthUserSpendCreditLimit, AuthUserSpendLimit } from "@/types/auth";

import { useCurrentLocale } from "./LanguageContext";

const accountAPIEndpoint = (host: string): string => `${host}/portal/consumers/account`;
const logoffAPIEndpoint = (host: string): string => `${host}/portal/consumers/auth/logout`;
const userSpendLimitAPIEndpoint = (host: string): string => `${host}/portal/consumers/limit`;
const userOrderTransactions = (host: string): string =>
    `${host}/portal/consumers/ordertransactions`;

function isAuthUserData(data?: any): data is AuthUserData {
    return (
        !!data &&
        typeof data === "object" &&
        typeof data.givenNames === "string" &&
        typeof data.surname === "string" &&
        typeof data.email === "string" &&
        typeof data.uuid === "string" &&
        typeof data.id === "number"
    );
}

interface ContextProps {
    readonly userData: AuthUserData;
    readonly userSpendLimit: string;
    readonly hasOrders: boolean;
    readonly logoffUser: () => void;
    readonly isAuthUserDataLoaded: boolean;
}

export const AuthContext = createContext<ContextProps>({
    userData: null,
    userSpendLimit: null,
    hasOrders: false,
    logoffUser: () => null,
    isAuthUserDataLoaded: true,
});

const resumePersistentLogin = async (url: string): Promise<boolean> => {
    try {
        const resumeLoginAxios = Axios.create({
            timeout: DEFAULT_REQUEST_TIMEOUT,
            headers: {
                "Content-Type": "application/json",
            },
        });
        // returns 201 on resume, 409 on conflict, 401 otherwise
        await resumeLoginAxios(`${url}/portal/consumers/persistent-login/resume`, {
            transformRequest: (data, headers) => {
                delete headers[CORRELATION_ID_HEADER_KEY];
                return data;
            },
            method: "POST",
            withCredentials: true,
            validateStatus: (status) => status === 201,
        });
        return true;
    } catch (e) {
        return false;
    }
};

const getAuthAxios = (apiHostUrl: string): AxiosInstance => {
    const authAxios = Axios.create({
        timeout: DEFAULT_REQUEST_TIMEOUT,
        headers: {
            "Content-Type": "application/json",
        },
    });

    const mutex = new Mutex();
    const onAuthenticationFailed = async (error): Promise<AxiosResponse> => {
        if (error.response?.status === 401) {
            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                if (await resumePersistentLogin(apiHostUrl)) {
                    const result = await authAxios.request(error.config);
                    release();
                    return result;
                }
            } else {
                await mutex.waitForUnlock();
                return authAxios.request(error.config);
            }
        }
        throw error;
    };
    authAxios.interceptors.response.use(null, onAuthenticationFailed);
    return authAxios;
};

export function useAuthUserData(): AuthUserData {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuthUserData must be used within a AuthProvider");
    }

    const authUserData = context?.userData || null;

    return authUserData;
}

export function useAuthIsUserLoggedIn(): boolean {
    return !!useAuthUserData();
}

export function useAuthLogoffUser(): () => void {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context.logoffUser;
}

export function useAuthUserSpendLimit(): string {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuthUserData must be used within a AuthProvider");
    }
    return context.userSpendLimit;
}

export function useAuthUserHasOrders(): boolean {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuthUserHasOrders must be used within a AuthProvider");
    }
    return context.hasOrders;
}

export function useIsAuthUserDataLoaded(): boolean {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuthUserData must be used within a AuthProvider");
    }
    return context.isAuthUserDataLoaded;
}

const AuthProvider: React.FC = ({ children }) => {
    const locale = useCurrentLocale();
    const apiHostUrl = API_HOST_LOCALE_MAP[locale];

    // This line will get the extracted cookie from the request - this is server side only
    const authUserDataReqCookie = getContinuationUserCookie();
    const storedUserDataString = authUserDataReqCookie;
    // This code is when we want to render in the BE and keep the state the same in the FE, required as part of the BE rendering solution
    /*
    const [storedUserDataString, setStoredUserDataString] = useCookies(
        AUTH_USER_DATA_COOKIE,
        authUserDataReqCookie
    );
    */

    const storedUserData = useMemo<AuthUserData>(() => {
        try {
            const decodedStoredUserDataString = decodeURIComponent(storedUserDataString);
            return JSON.parse(decodedStoredUserDataString);
        } catch (ex) {
            logger.logException(ex, "AuthContext: failed when parsing storedUserData");
            return null;
        }
    }, [storedUserDataString]);

    const [isAuthUserDataLoaded, setIsAuthUserDataLoaded] = useState<boolean>(false);

    const [didLoadedAccountData, setDidLoadedAccountData] = useState<boolean>(false);
    const [userData, setUserData] = useState<AuthUserData>(storedUserData);
    const isUserLoggedIn = useMemo<boolean>(() => isAuthUserData(userData), [userData]);
    const [userHasOrders, setUserHasOrders] = useState(false);
    const [userSpendLimitData, setUserSpendLimitData] = useState<AuthUserSpendCreditLimit>(null);

    const { formatMoney } = useMoney({
        currency: userSpendLimitData?.currency,
        locale: locale !== "global" ? locale : "default",
        decimalPlaces: 2,
    });
    const userSpendLimit = userSpendLimitData ? formatMoney(userSpendLimitData.amount) : null;

    const logoffUser = useCallback(() => {
        if (!apiHostUrl) {
            return;
        }
        axios(logoffAPIEndpoint(apiHostUrl), {
            method: "POST",
            withCredentials: true,
        });
        setUserData(null);
    }, [apiHostUrl]);

    useEffect(() => {
        setUserDataProperties(userData);
        // setStoredUserDataString(JSON.stringify(userData));
    }, [userData]);

    const axiosWithPersistentLoginInterceptor = useMemo(() => {
        return getAuthAxios(apiHostUrl);
    }, [apiHostUrl]);

    useEffect(() => {
        if (!apiHostUrl) {
            setIsAuthUserDataLoaded(true);
            return;
        }

        const accountController = new AbortController();
        axiosWithPersistentLoginInterceptor
            .get<AuthUserData>(accountAPIEndpoint(apiHostUrl), {
                withCredentials: true,
                signal: accountController.signal,
            })
            .then((res) => {
                setUserData(isAuthUserData(res.data) ? res.data : null);
            })
            .catch((ex) => {
                setUserData(null);
            })
            .finally(() => {
                setDidLoadedAccountData(true);
            });
        return () => accountController.abort();
    }, [apiHostUrl, axiosWithPersistentLoginInterceptor]);

    useEffect(() => {
        if (!didLoadedAccountData || isAuthUserDataLoaded) {
            return;
        } else if (!isUserLoggedIn) {
            setIsAuthUserDataLoaded(true);
            return;
        }

        const spendLimitController = new AbortController();
        const orderController = new AbortController();
        Promise.all([
            axios.get<AuthUserSpendLimit>(userSpendLimitAPIEndpoint(apiHostUrl), {
                withCredentials: true,
                signal: spendLimitController.signal,
            }),
            axios.get(userOrderTransactions(apiHostUrl), {
                withCredentials: true,
                params: { limit: 10, offset: 0 },
                signal: orderController.signal,
            }),
        ])
            .then(([userSpendLimitRes, orderRes]) => {
                setUserSpendLimitData(userSpendLimitRes?.data?.creditLimit);
                setUserHasOrders(orderRes?.data?.results?.length !== 0 ? true : false);
            })
            .catch((ex) => {
                setUserSpendLimitData(null);
            })
            .finally(() => {
                setIsAuthUserDataLoaded(true);
            });
        return () => {
            spendLimitController.abort();
            orderController.abort();
        };
    }, [didLoadedAccountData, apiHostUrl, isUserLoggedIn, isAuthUserDataLoaded]);

    return (
        <AuthContext.Provider
            value={{
                userData,
                userSpendLimit,
                logoffUser,
                isAuthUserDataLoaded,
                hasOrders: userHasOrders,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
